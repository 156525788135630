<template>
  <div class="container-app">
    <div class="questions">
      <Header active_tab="revision" />
      <div class="prev-made-questions">
        <ol type="1">
          <li v-for="(question, index) in questions" :key="index">
            <div class="row" justify-content-between>
              <div class="col my-2 question">{{ question.question }}</div>
              <div class="col-1">({{ question.marks }})</div>
            </div>
            <ol v-if="question.propositions" type="A">
              <li
                v-for="(item, ind) in question.propositions"
                :key="ind"
                :style="
                  item['props'] == question.correct_option
                    ? { color: '#24cb24' }
                    : ''
                "
              >
                {{ item["props"] }}
              </li>
            </ol>
            <div v-if="question.subType == 0" style="color: #24cb24">
              {{ question.correct_option }}
            </div>
            <div v-if="question.answer">
              {{ question.answer }}
            </div>

            <button
              class="btn btn-danger my-3 mx-2"
              @click="deleteQuestion(index)"
            >
              Delete
            </button>
          </li>
        </ol>
      </div>
      <div class="row justify-content-center my-3" v-if="!edit_open">
        <input
          class="input-file"
          type="file"
          @change="exportData"
          accept=".xlsx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />

        <button class="btn btn-success my-5" @click="checkFile">
          Add Question From Excel
        </button>

        <button @click="edit_open = true" class="btn btn-primary my-5">
          Add MCQ
        </button>

        <button
          @click="
            edit_open = true;
            mcq = false;
          "
          class="btn btn-warning my-5"
        >
          Add Theoritical
        </button>
      </div>
      <div class="mx-auto editable-ques my-5 py-3" v-else>
        <div>
          <textarea
            class="form-control mb-3"
            v-model="current_question"
            placeholder="Enter Question Here"
            rows="5"
          ></textarea>
          <select
            v-if="!mcq"
            v-model="subType"
            class="form-control mb-4"
            name="subType"
          >
            <option disabled value="-1">Select Type of Question</option>
            <option value="0">OneWord</option>
            <option value="1">Long/Short Answer</option>
          </select>
          <div v-if="subType == 0 && !mcq">
            <input
              class="form-control my-1 col"
              v-model="correct_option"
              maxlength="100"
              :placeholder="'Correct Answer'"
            />
          </div>
        </div>

        <div v-if="mcq">
          <div
            class="row my-1"
            v-for="(item, index) in current_options"
            :key="index"
          >
            <input
              class="form-control col-1 align-self-center"
              type="radio"
              :value="index"
              v-model="selected_option"
            />
            <input
              class="form-control my-1 col"
              v-model="current_options[index]"
              :placeholder="'Option ' + (index + 1)"
            />
          </div>
          <div class="btn btn-info ml-4 my-3" @click="addOption">
            Add Option
          </div>
        </div>
        <div v-else>
          <div>
            <textarea
              class="form-control mb-3"
              v-model="answer"
              placeholder="Enter Explanation Here"
              rows="10"
            ></textarea>
          </div>
        </div>
        <label>Marks</label>
        <div>
          <input
            class="form-control"
            type="number"
            id="marks"
            v-model="marks"
            min="1"
          />
        </div>
        <small>This question marks</small>
        <div>
          <button class="btn btn-primary" @click="validate2">
            Add Question
          </button>
        </div>
      </div>
      <div class="justify-content-center d-flex my-5">
        <button class="btn btn-success" @click="redirect">Home</button>
      </div>
    </div>
  </div>
</template>
<script>
import db from "../../firebase.js";
import axios from "axios";
import store from "@/store";
import Header from "@/components/Header";
import XLSX from "xlsx";

export default {
  name: "EditRevision",
  components: { Header },
  props: ["revision_id"],

  mounted() {
    //For this test-id set questions
    var config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(
        "https://Pysberduck.pythonanywhere.com/api/edit-revision",
        {
          revision_id: this.revision_id,
        },
        config
      )
      .then(
        (res) => {
          if (res.data["status"] == "200") {
            this.questions = res.data["data"]; //Questions List
          } else if (res.data == "500") swal("Test Not made yet");
          else if (res.data == "400") swal("Test does not exist");
          else swal("Some error soccured");
        },
        (err) => {
          swal("Some error occured");
        }
      );
    store.state.loading = false;
  },
  data() {
    return {
      selected_option: -1,
      title: "",
      start_time: "",
      end_time: "",
      edit_open: false,
      mcq: true,
      current_question: "",
      current_options: ["", ""],
      correct_option: "",
      marks: -1,
      answer: "",
      questions: [],
      subType: -1,
    };
  },
  computed: {
    total() {
      let sum = 0;
      this.questions.forEach((elem) => (sum += +elem["marks"]));
      return sum;
    },
  },
  methods: {
    exportData(event) {
      if (!event.currentTarget.files.length) {
        return;
      }
      var questions = this.questions;
      const that = this;
      // Get the file object
      var f = event.currentTarget.files[0];
      // Use FileReader to read
      var numberOfQuestions = this.numberOfQuestionsFromExcel;
      var reader = new FileReader();
      // Rewrite the readAsBinaryString method on FileReader
      FileReader.prototype.readAsBinaryString = (f) => {
        var binary = "";
        var wb; // Read the completed data
        var outdata; // the data you need
        var reader = new FileReader();
        reader.onload = (e) => {
          // Read into Uint8Array and convert to Unicode encoding (Unicode takes two bytes)
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // Next is xlsx, see the plugin api for details
          wb = XLSX.read(binary, {
            type: "binary",
          });
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          // Custom method to pass data to parent component
          var question = "";
          var marks = 0;
          var explanation = "";
          var correct = "";
          var section = "";
          var difficulty = "";

          for (var q in outdata) {
            question = outdata[q].question;
            difficulty = outdata[q].difficulty;
            marks = outdata[q].marks;
            explanation = outdata[q].explanation;
            section = outdata[q].section.toString();
            var di = {};
            di["question"] = question;
            di["marks"] = marks;

            di["subType"] = 2;
            if (outdata[q].correct != null) {
              correct = outdata[q].correct.toString();
              if (outdata[q].options != null) {
                var temp_options = outdata[q].options.split(";");
                var options = [];
                for (var x in temp_options) {
                  options.push({
                    props: temp_options[x].toString(),
                  });
                }
                di["options"] = options;
                di["propositions"] = options;
              } else {
                di["subType"] = 0;
              }
              di["correct_option"] = correct;
              di["correct"] = correct;
            }

            questions.push(di);
            this.saveSingleQuestion(di);
          }
        };
        reader.readAsArrayBuffer(f);
      };

      reader.readAsBinaryString(f);

      this.questions = questions;
    },
    saveSingleQuestion(di) {
      // const fun = di => {
      var i = this.questions.length - 1;

      let myid = 0;

      if (this.questions.length > 1)
        myid = this.questions[this.questions.length - 2].id + 1;
      di["id"] = myid;
      this.questions.push(di);
      db.collection("revisions")
        .doc(this.revision_id)
        .collection("questions")
        .add(di)
        .then((doc) => {
          this.questions[i]["question_id"] = doc.id;
          this.questions[i]["id"] = myid;
        });
      // };
    },
    checkFile() {
      document.querySelector(".input-file").click();
    },
    scroll(ev) {
      var st = Math.max(
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
      st = st + document.documentElement.clientHeight;
      var x = Math.abs(st - document.documentElement.scrollHeight);
      console.log(x);
      if (x <= 2) {
        this.getNextPage(this.current_section_name);
      }
    },
    redirect() {
      this.$router.push({ name: "Admin" });
    },
    validate2() {
      if (this.current_question.length == 0) {
        swal("Question Lenght should be greater than zero");
        return;
      }
      if (this.mcq) {
        if (this.selected_option == -1) {
          swal("Select a valid option");
          return;
        }
        this.correct_option = this.current_options[this.selected_option];
        for (var i = 0; i < this.current_options.length; i++) {
          if (this.current_options[i] == "") {
            swal("Fill up all options");
            return;
          }
        }
      } else {
        if (this.answer.length == 0) {
          swal("Enter a valid answer");
          return;
        }
      }
      if (this.marks <= 0) {
        swal("Set marks >0");
        return;
      }
      //All conditions are fullfilled that means add this question to questions list

      var di = {};
      di["question"] = this.current_question;
      di["propositions"] = [];
      if (this.mcq) {
        for (var i = 0; i < this.current_options.length; i++) {
          di["propositions"].push({
            props: this.current_options[i],
          });
        }
      } else {
        di["answer"] = this.answer;
      }
      di["correct_option"] = this.correct_option;
      di["marks"] = this.marks;
      di["subType"] = this.mcq ? 2 : this.subType; //2=>mcq,1=>subjec,oneword=>0
      this.questions.push(di);
      this.saveQuestion();
      this.init();
    },
    init() {
      (this.edit_open = false),
        (this.mcq = true),
        (this.current_question = ""),
        (this.current_options = ["", ""]),
        (this.correct_option = ""),
        (this.answer = "");
      this.marks = -1;
    },
    deleteQuestion(index) {
      //Delete question with this id
      db.collection("revisions")
        .doc(this.revision_id)
        .collection("questions")
        .doc(this.questions[index]["question_id"])
        .delete()
        .then(() => {
          this.questions.splice(index, 1);
        })
        .catch((e) => swal(e));
    },
    addOption() {
      this.current_options.push("");
    },
    async saveQuestion() {
      //Update the data on firestore

      //Now add all questions in
      let myid = 0;
      if (this.questions.length > 1)
        myid = this.questions[this.questions.length - 2].id + 1;

      var i = this.questions.length - 1;

      if (this.questions[i].propositions.length != 0) {
        db.collection("revisions")
          .doc(this.revision_id)
          .collection("questions")
          .add({
            question: this.questions[i].question,
            marks: +this.questions[i].marks,
            correct: this.questions[i].correct_option,
            id: myid,
            options: this.questions[i].propositions,
            subType: this.questions[i].subType,
          })
          .then((doc) => {
            this.questions[i]["question_id"] = doc.id;
            this.questions[i]["id"] = myid;
          });
      } else {
        var subjective = {
          question: this.questions[i].question,
          answer: this.questions[i].answer,
          marks: +this.questions[i].marks,
          subType: this.questions[i].subType,
          id: myid,
        };
        if (subjective.subType == 0) {
          subjective["correct"] = this.questions[i].correct_option;
        }
        db.collection("revisions")
          .doc(this.revision_id)
          .collection("questions")
          .add(subjective)
          .then((doc) => {
            //doc.id
            this.questions[i]["id"] = myid;
            this.questions[i]["question_id"] = doc.id;
          });
      }
      await db.collection("revisions").doc(this.revision_id).update({
        total: this.total,
      });
    },
  },
};
</script>
<style scoped>
.question {
  font-size: 20px;
  font-weight: 600;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  letter-spacing: 2px;
}

.editable-ques {
  width: 60%;
  margin-top: 20px;
}

.header-quiz {
  display: flex;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #e7eae0;
  justify-content: center;
  align-items: center;
  background-color: #e7eae0;
  border-radius: 10px 10px 0px 0px;
}

.container-app {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.container-quiz {
  display: flex;
  width: 40%;
  height: 85%;
  background-color: white;
  text-align: center;
  flex-flow: column;
  border: 1px solid #e7eae0;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.questions {
  width: 100%;
}

.input-file {
  display: none;
}

.btn {
  margin-right: 10px;
}
</style>
